<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <v-icon>layers</v-icon>
            FIFO (First In First Out)
            <v-spacer></v-spacer>
            <v-btn
              fab
              small
              style="z-index: 1; background: blue"
              dark
              @click="DownloadFifo"
            >
              <v-icon dark>cloud_download</v-icon>
            </v-btn>
          </v-card-title>
         
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr v-if="props.index == 0">
                <td colspan="6">Item Opening Amount</td>
                <td>{{form.items.data[0].opening_amount ? form.items.data[0].opening_amount : '0.00'}}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Form from '@/library/Form';
import { passDataToExternalDomain } from '../../../../library/helpers';

export default {
  data: () => ({
    form: new Form(
      {
        name: '',
        description: '',
        is_primary: false,
        parent_id: '',
      },
      '/api/report/inventory-closing'
    ),
    headers: [
      {
        text: 'Date',
        value: 'date',
        align: 'left',
        width: 130,
        sortable: false,
      },
      {
        text: 'Item',
        value: 'item',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Quantity',
        value: 'quantity',
        align: 'left',
        width: 100,
        sortable: false,
      },
      {
        text: 'Rate',
        value: 'rate',
        align: 'left',
        width: 150,
        sortable: false,
      },
      {
        text: 'Amt. Purchase',
        value: 'amt_purchase',
        align: 'left',
        sortable: false,
        width: 150,
      },

      {
        text: 'Amt. Sales',
        value: 'amt_sales',
        align: 'left',
        sortable: false,
        width: 150,
      },
      {
        text: 'Balance.',
        value: 'balance',
        align: 'right',
        sortable: false,
        width: 200,
      },
    ],
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
    },
    downloadUrl: '',
  }),
  computed: {},
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.form.get().then(({ data }) => {
        this.downloadUrl = data.download_url;
      });
    },
    DownloadFifo() {
      passDataToExternalDomain();
      setTimeout(() => {
        window.open(this.downloadUrl);
      }, 300);
    },
  },
};
</script>
